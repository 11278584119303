"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTimestamp = exports["default"] = void 0;
// This is .js for preval

var versionType = "PROD";
var versionNumber = Math.floor(1728473967578 / 1000);
var shortCommitSha = "0a188d2";
var getTimestamp = exports.getTimestamp = function getTimestamp(version) {
  return +version.split('-')[1];
};
var _default = exports["default"] = "PROD-1728473967-0a188d2";